import React from 'react';
import { FormikConsumer } from 'formik';
import FormikWizard from "../FormikWizard";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Fields } from '../FieldInfo';

export default class ReviewAndSubmit extends React.Component {
    render() {

        const { classes } = this.props;

        return (
            <FormikWizard.Page>
                <Typography variant="h6" gutterBottom>
                    Review and submit
                </Typography>
                <FormikConsumer>
                    {({ validationSchema, validate, onSubmit, ...rest }) => (
                        <List disablePadding>
                            {Object.keys(Fields).map(fieldKey => {
                                const field = Fields[fieldKey];
                                const fieldValue = rest.values[fieldKey];
                                return (
                                    <ListItem className={classes.listItem} key={fieldKey}>
                                        <ListItemText primary={field.label} secondary={field.description}/>
                                        {fieldValue ?
                                            <Typography variant="body2">{fieldValue}</Typography>
                                            :
                                            <Typography variant="caption" style={{fontStyle:'italic', opacity:0.4}}>(not given)</Typography>
                                        }

                                    </ListItem>
                                )
                            })}
                        </List>
                    )}
                </FormikConsumer>
            </FormikWizard.Page>
        );
    }
}