import _ from 'lodash';

export const Fields = {
    firstName: {
        label:  'First Name',
        default:'',
        description:'Your first name'
    },
    lastName: {
        label:  'Last Name',
        default:'',
        description:'Your last name'
    },
    email: {
        label:  'Email address',
        default:'',
        description:'Email address where we can reach you'
    },
    title: {
        label:  'Title',
        default:'',
        description:'Your title at the company'
    },
    company: {
        label:  'Company',
        default:'',
        description:'Name of company'
    },
    address1: {
        label:  'Address line 1',
        default:'',
        description:'Mailing address, line 1'
    },
    address2: {
        label:  'Address line 2',
        default:'',
        description:'Mailing address, line 2'
    },
    city: {
        label:  'City',
        default:'',
        description:'Mailing address city'
    },
    state: {
        label:  'State or Province',
        default:'',
        description:'Mailing address state'
    },
    country: {
        label:  'Country',
        default:'',
        description:'Mailing address country'
    },
    geolocation: {
        label:  'Geolocation',
        default:'',
        description:'AWS Geolocation'
    }
};

export const FieldLabels = _.mapValues(Fields, 'label');
export const FieldDefaults = _.mapValues(Fields, 'default');
export const FieldDescriptions = _.mapValues(Fields, 'description');

