import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#2CB7C6'
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        borderRadius: '8px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        borderRadius: '20px',
        width: '40px',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    buttonPrimary: {
        backgroundColor: '#2a55c3',
        color: '#fff',
    },
    table: {
        minWidth: 650,
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
    descriptionText: {
        margin: '32px',
        textAlign: 'left'
    },
    subHeading: {
        paddingBottom: '0px'
    }
}));