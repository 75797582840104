import FormikWizard from "./FormikWizard";
import React, {useState} from "react";
import FormPage1 from "./FormPages/FormPage1";
import FormPage2 from "./FormPages/FormPage2";
import ReviewAndSubmit from "./FormPages/ReviewAndSubmit";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "./logo.png";
import cutover from "./cutover.png";
import Paper from "@material-ui/core/Paper";
import Copyright from "./Copyright";
import { FieldDefaults } from "./FieldInfo";
import { useStyles } from "./FormStyles";
import { storeSessionData } from "./Helpers";
import {Box} from "@material-ui/core";
import formValidationSchema from "./FormPages/FormValidationSchema";
import LoginLogo from './cutover-logo.png'

const stepNames = ["Your Information", "Instance Details", "Review and submit"];

storeSessionData();

const sessionData = JSON.parse(sessionStorage.getItem("client"));

const postData = (url = "", data = {}) => {
  return fetch(url, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: JSON.stringify(sessionData)
    },
    redirect: "follow",
    body: JSON.stringify(data)
  })
};

export default function ProvisionForm() {
  const classes = useStyles();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ submitted, setSubmitted ] = useState(false);
  return (
    <React.Fragment>
      <CssBaseline />
      <img src={LoginLogo} className="cutover-logo" />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Cutover - Instance Configuration
          </Typography>
          {!submitted &&
          <FormikWizard
              classes={classes}
              stepNames={stepNames}
              debugMode={false}
              initialValues={FieldDefaults}
              showAllSteps={false}
              validationSchema={formValidationSchema}
              onSubmit={(values, actions) => {
                postData("/provision-submit", values, sessionData)
                    .then((response) => {
                      actions.setSubmitting(false);
                      if (response.status !== 200) {
                        setErrorMessage(`Error submitting form: ${response.statusText}`)
                      } else {
                        setSubmitted(true);
                      }
                    })
                    .catch(e => {
                      actions.setSubmitting(false);
                      setErrorMessage(`Error submitting form: ${e.message}`);
                    });
              }}
          >
            <FormPage1 classes={classes}/>
            <FormPage2 classes={classes}/>
            <ReviewAndSubmit classes={classes}/>
          </FormikWizard>
          }
          {submitted &&
          <Box>
            <Typography variant="body1" className={classes.descriptionText}>
            Thank you for choosing Cutover to support your AWS migration journey. We have received your details and are working on getting you up and running. Here’s what to expect:
            <ol>
              <li>You will receive an email invitation with a registration link from Cutover once the set up is completed.</li>
              <li>Once you are registered and logged in, you will be shown a short guided tour of Cutover to get you started.</li>
              <li>Follow the tasks and guidance outlined in the Cutover platform.</li>
            </ol>
            Again, thank you for choosing Cutover and we look forward to supporting you along your AWS migration journey.
            </Typography>
          </Box>
          }
          {errorMessage &&
            <Box color="error.main">
              {errorMessage}
            </Box>
          }
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
