import React from 'react';
import { Field } from 'formik';
import FormikWizard from "../FormikWizard";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputAdornment, Radio,
    Table, TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import { RadioGroup } from 'formik-material-ui';
import { TextField } from 'formik-material-ui';
export default class FormPage3 extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <FormikWizard.Page>

                <Typography variant="h6" gutterBottom className={classes.subHeading}>
                    AWS Geolocation
                </Typography>

                <Typography variant="body2" gutterBottom className={classes.descriptionText}>
                    Please choose the geolocation where you will like your Cutover instance to be hosted.
                </Typography>

                    <Grid item xs={12} md={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Geolocation</FormLabel>
                            <Field component={RadioGroup} name="geolocation" required>
                                <FormControlLabel value="US" control={<Radio />} label="US (United States)" />
                                <FormControlLabel value="EU" control={<Radio />} label="EU (Europe)" />
                            </Field>
                        </FormControl>
                    </Grid>

            </FormikWizard.Page>
        );
    }
}