import * as Yup from "yup";

const formValidationSchema = [];

formValidationSchema[0] = Yup.object().shape({
    email: Yup.string().email().required('Required')
});

formValidationSchema[1] = Yup.object().shape({
    geolocation: Yup.string().required('Required')
});

export default formValidationSchema;