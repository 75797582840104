import React from 'react';
import {ErrorMessage, Field} from 'formik';
import FormikWizard from "../FormikWizard";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { TextField } from 'formik-material-ui';
import { Box } from "@material-ui/core";

const required = value => (value ? undefined : 'Required');

export default class FormPage1 extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <FormikWizard.Page
                validate={values => {
                    return {}
                }}
            >
                <Typography variant="body2" gutterBottom className={classes.descriptionText}>
                    Your instance of Cutover is a Virtual Private Cloud created in Amazon Web Services (AWS).
                    There are certain aspects which can be configured in accordance with your requirements.<br/>
                    <br/>
                    Please fill in the values in the following sections, Cutover will use this questionnaire to build your instance.
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="firstName"
                            label="First Name"
                            autoComplete="fname"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="lastName"
                            label="Last Name"
                            autoComplete="lname"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="email"
                            label="Email Address"
                            autoComplete="email"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="title"
                            label="Title"
                            autoComplete="title"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="company"
                            label="Company"
                            autoComplete="company"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="address1"
                            label="Address line 1"
                            autoComplete="address-line1"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="address2"
                            label="Address line 2"
                            autoComplete="address-line2"
                            component={TextField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="city"
                            label="City"
                            autoComplete="city"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="state"
                            label="State/Province/Region"
                            autoComplete="state"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="zip"
                            label="Zip / Postal code"
                            autoComplete="zip"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name="country"
                            label="Country"
                            autoComplete="country"
                            component={TextField}
                            validate={required}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </FormikWizard.Page>
        );
    }
}