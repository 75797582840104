import React from 'react';
import { Formik } from 'formik';
import { Debug } from './Debug';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";


export default class FormikWizard extends React.Component {
    static Page = ({ children }) => children;

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            values: props.initialValues,
        };
    }

    next = values =>
        this.setState(state => ({
            activeStep: Math.min(state.activeStep + 1, this.props.children.length - 1),
            values,
        }));

    previous = () =>
        this.setState(state => ({
            activeStep: Math.max(state.activeStep - 1, 0),
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.activeStep
            ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    totalSteps = () => {
        const { children } = this.props;
        return React.Children.count(children);
    };

    percentCompleted = () => {
        const { activeStep } = this.state;
        return 100.0 * (activeStep+1) / this.totalSteps();
    };

    handleSubmit = (values, bag) => {
        const { onSubmit } = this.props;
        const { activeStep } = this.state;
        const isLastPage = activeStep === this.totalSteps() - 1;
        if (isLastPage) {
            return onSubmit(values, bag);
        } else {
            bag.setTouched({});
            bag.setSubmitting(false);
            this.next(values);
        }
    };

    render() {
        const { children, classes, stepNames, debugMode, showAllSteps, validationSchema } = this.props;
        const { activeStep, values } = this.state;
        const activePage = React.Children.toArray(children)[activeStep];
        const isLastPage = activeStep === this.totalSteps() - 1;
        return (
            <Formik
                initialValues={values}
                enableReinitialize={false}
                validate={this.validate}
                validationSchema={validationSchema[activeStep]}
                onSubmit={this.handleSubmit}
                render={({ values, handleSubmit, isSubmitting, handleReset }) => (
                    <form onSubmit={handleSubmit}>
                        {activePage}
                        <div style={{height: "30px"}} />
                        {showAllSteps ?
                            <Stepper activeStep={activeStep} className={classes.stepper}>
                                {stepNames.map(label => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            :
                            <>
                                <Typography variant="body2" gutterBottom>
                                    Step {activeStep+1} of {this.totalSteps()}
                                </Typography>
                                <LinearProgress variant="determinate" value={this.percentCompleted()}/>
                            </>
                        }

                        <div className={classes.buttons}>
                            {activeStep !== 0 && (
                                <Button onClick={this.previous} className={classes.button}>
                                    Back
                                </Button>
                            )}
                            {!isLastPage &&
                                <Button
                                    variant="contained"
                                    type="submit"
                                    //onClick={this.next} // change to type="submit" to validate on page change
                                    className={`${classes.button} ${classes.buttonPrimary}`}
                                >
                                    Next
                                </Button>
                            }
                            {isLastPage &&
                            <Button
                                variant="contained"
                                type="submit"
                                className={`${classes.button} ${classes.buttonPrimary}`}
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                            }
                        </div>
                        {debugMode ? <Debug /> : null}
                    </form>
                )}
            />
        );
    }
}