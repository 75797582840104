export function storeSessionData() {
  var sPageURL = window.location.search.substring(1);
  if (!sPageURL) {
    var para = document.createElement("P");
    para.innerText = "";
    document.body.appendChild(para);
  }
  var sURLVariables = sPageURL.split("&");
  var paramsObj = {};
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split("=");
    paramsObj[sParameterName[0]] = sParameterName[1];
  }

  sessionStorage["client"] = JSON.stringify(paramsObj);
}

export default storeSessionData;
